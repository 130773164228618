
export function saveLanguage({commit}, payload) {
    console.log("saveLanguage: " + payload);
    commit('setLanguage', payload);
}
export function saveLoginToken({commit}, payload) {
    console.log("saveLoginToken");
    commit('setLoginToken', payload);
}
export function saveUsername({commit}, payload) {
    commit('setUsername', payload);
}
export function saveProfile({commit}, payload) {
    commit('setProfile', payload);
}
export function saveWebp({commit}, payload) {
    commit('setWebp', payload);
}
export function showStickyHeader({commit}, payload) {
    commit('setShowStickyHeader', payload);
}
export function logOut({commit}, payload) {
    commit('logOut', payload);
}
