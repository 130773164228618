import {i18n} from "@/main";
import VueCookies from 'vue-cookies';

export function getLanguage (state) {
  console.log('Language: ' + state.language)
  let language = state.language;
  if (!language) language = 'en'
  i18n.locale = language;
  VueCookies.set('site_language' , language);
  return language;
}

export function getWebpSupported (state) {
  return state.webpSupported;
}
export function getShowStickyHeader (state) {
  return state.showStickyHeader;
}

export function isAuthenticated (state) {
  return state.loginToken;
}

export function getUsername (state) {
  return state.username;
}

export function getProfile (state) {
  return state.profile;
}

export function getAdminHeader (state) {
  return {
    headers: {
      Authorization: 'Basic ' + btoa(state.username + ":" + state.loginToken)
    }
  };
}

export function getMultipartAdminHeader (state) {
  return {
    headers: {
      Authorization: 'Basic ' + btoa(state.username + ":" + state.loginToken),
      'Content-Type': 'multipart/form-data'
    }
  };
}
